import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import QuotationMark from '../../../components/frills/QuotationMark';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';
import H1 from '@rotaready/frecl/build/H1';
import H4 from '@rotaready/frecl/build/H4';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';

const isBrowser = typeof window !== 'undefined';

const Hero = styled.div`
  padding: 60px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0 100px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row-reverse;
    text-align: left;
    align-items: center;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 540px;
    margin-left: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;
  text-align: center;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const BenefitBlock = styled.div`
  padding: 40px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.brand10};
  margin-bottom: 20px;
`;

const QuoteBar = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand160};
`;

const QuoteInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
`;

const QuoteTitle = styled.div`
  margin-bottom: 20px;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.bodyTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.bodyTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="ResDiary integration - Rotaready"
          description="A game changing integration for hospitality businesses, designed to give you real-time visibility over reservations and rotas, all in one place."
          url="integrations/resdiary"
        />

        <ResponsiveContainer>
          <Hero>
            <HeroInnerContainer>
              <HeroBody>
                <HeroTextWrapper>
                  <H1 uistyle="brand160" text="ResDiary and Rotaready" />
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">A game changing integration for hospitality businesses, designed to give you real-time visibility over reservations and rotas, all in one place.</H4>
                </HeroTextWrapper>

                <HeroTextWrapper>
                  <H4 uistyle="grey">With reservations past and present, fed directly from ResDiary into Rotaready, this free integration also enhances our demand forecasting functionality.</H4>
                </HeroTextWrapper>

                <CtaButtons>
                  <Button uistyle="primary" text="Learn more" size="lg" onClick={this.goToForm} />

                  <a href="https://sales.resdiary.com" target="_blank" rel="noopener noreferrer">
                    <Button uistyle="primary" text="Visit ResDiary" ghost borderless size="lg" />
                  </a>
                </CtaButtons>
              </HeroBody>

              <HeroIllustration>
                <GatsbyImage
                  image={data.heroImage.childImageSharp.gatsbyImageData}
                  alt="Screenshots of Rotaready and ResDiary"
                  style={{ maxWidth: '450px' }}
                />
              </HeroIllustration>
            </HeroInnerContainer>
          </Hero>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="Supercharged staff scheduling" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="The integration between ResDiary and Rotaready allows real-time reservation data, which includes all covers, bookings, and any changes made to bookings, to be displayed within Rotaready. This data appears across all key tools and reports within Rotaready, including the rota editor and cost control." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="It's also available to power Rotaready's demand forecasting. A feature that utilises reservation data to predict future sales and upcoming demand- so you can be sure you always schedule the perfect number of staff to meet demand on any given day." />
              </TextBlock>
              <TextBlock>
                <H3 uistyle="brand160" text="Already a Rotaready customer?" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Setting up the integration is easy. Drop us a live chat or an email and our customer success team will guide you through it." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.bodyTopRef}>
              <TextBlock>
                <H3 uistyle="brand160" text="About ResDiary" />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Seamless reservation and table management." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="ResDiary makes it easy to manage your restaurant reservations, delivery and takeaway orders all in one place, without the hefty commission charges." />
              </TextBlock>

              <BenefitBlock>
                <TextBlock>
                  <UL size="lg" uistyle="normal" iconUistyle="primary">
                    <TextListItem text="Offer customers a seamless experience and increase reservations with an online widget and personalised booking features." />
                    <TextListItem text="See your bookings and manage tables all in one place." />
                    <TextListItem text="Secure your revenue, process payments, and reduce no-shows." />
                  </UL>
                </TextBlock>
              </BenefitBlock>

              <TextBlock>
                <LinkWithArrow
                  direction="right"
                  text="Find out more about ResDiary"
                  render={props => <a {...props} href="https://sales.resdiary.com" target="_blank" rel="noopener noreferrer" />}
                />
              </TextBlock>
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>

        <QuoteBar>
          <ResponsiveContainer>
            <QuoteInnerWrapper>
              <QuotationMark />
              <QuoteTitle>
                <H3 uistyle="white" text="Being able to see real-time bookings from ResDiary within Rotaready, means I can make sure every team member is in the right place at the right time. The integration is simply brilliant!" />
              </QuoteTitle>
              <Text size="lg" uistyle="white" text="David Scott - CEO, The Hotel Folk" />
            </QuoteInnerWrapper>
          </ResponsiveContainer>
        </QuoteBar>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "integrations/resdiary/hero-desktop.webp" }) {
      childImageSharp {
        gatsbyImageData(quality:100)
      }
    }
  }
`;
